// extracted by mini-css-extract-plugin
export var container = "LogoutPage-module--container--3u1Tg";
export var title = "LogoutPage-module--title--1b-xX";
export var description = "LogoutPage-module--description--SN_2Z";
export var btnContainer = "LogoutPage-module--btnContainer--1WAbJ";
export var btnYes = "LogoutPage-module--btnYes--3H_OR";
export var btnNo = "LogoutPage-module--btnNo--1Bx4Q";
export var logoutFromAllDevicesContainer = "LogoutPage-module--logoutFromAllDevicesContainer--3Lv1K";
export var checkboxContainer = "LogoutPage-module--checkboxContainer--2IRP3";
export var checkbox = "LogoutPage-module--checkbox--oLiMp";
export var checkboxLabel = "LogoutPage-module--checkboxLabel--1N_AN";