import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Notification, SEO } from "components";
import { customerAccountLogout } from "state/LoginPage/actions";
import { actionCreators, selectors } from "state/LoginPage/reducer";
import * as styles from "./LogoutPage.module.scss";

const LogoutPage = ({
  customerAccountLogout,
  loginState,
  setIsLogoutFromAllDevices,
}) => {
  const [notification, showNotification] = useState();

  useEffect(() => {
    showNotification("You are loged out successfully!");
    trackingGA()
  }, []);

  useEffect(() => {
    showNotification(loginState.UIState.error);
  }, [loginState.UIState.error]);

  const logoutHandler = () => {
    customerAccountLogout();
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <SEO pathname='/logout' />
      <div className={styles.title}>Logout </div>

      <div className={styles.description}>Are you sure you want log out?</div>
      <div className={styles.btnContainer}>
        <button className={styles.btnNo} onClick={() => navigate('/')}>
          No
        </button>
        <button className={styles.btnYes} onClick={() => logoutHandler()}>
          Yes
        </button>
      </div>

      <div className={styles.logoutFromAllDevicesContainer}>
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id="checkbox"
            type="checkbox"
            name="check"
            value="check1"
            onClick={() => {
              setIsLogoutFromAllDevices(!loginState.isLogoutFromAllDevices);
            }}
            defaultChecked={false}
          />
          <label className={styles.checkboxLabel} htmlFor="checkbox">
            Logout from all devices
          </label>
        </div>
      </div>
      <Notification message={notification} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginState: selectors.selectLoginState(state),
});

const mapDispatchToProps = {
  customerAccountLogout,
  setIsLogoutFromAllDevices: actionCreators.setIsLogoutFromAllDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
